/* globals LightBox, Masonry, imagesLoaded, Ten4Slideshow */

// polyfills
import './vendor/polyfills/array-from-polyfill.js';

// libs
// import './vendor/respond.min.js';
import Masonry from 'masonry-layout';
// import './vendor/masonry.pkgd.min.js';

// import './vendor/imagesloaded.pkgd.min.js';
import imagesLoaded from 'imagesloaded';
import './vendor/ten4slideshow.js';
import './vendor/ajax.min.js';
import './vendor/search.js';
import Uncloak from './vendor/uncloak.js';
import 'svgxuse';

// helpers & variables
import { breakpoints } from '../config.yml';
import { getWindowWidth } from './helpers.js';

// modules
import { LightBox } from './vendor/lightbox.js';

// main.js

( () => {
	// Cookie notice
	if ( document.cookie.indexOf( 'seenCookieNotice' ) === -1 ) {
		document.cookie = 'seenCookieNotice=yes;path=/;max-age=31536000;secure';
		document.body.classList.add( 'show-cookie-notice' );
		document.querySelector( '.cookie-notice button' ).addEventListener( 'click', () => {
			document.body.classList.remove( 'show-cookie-notice' );
		} );
	}
} )();

( () => {
	// Uncloak
	new Uncloak( {
		progVars: { m: 80, c: 100 }
	} );
} )();

( () => {
	// Masonry layout
	const grid = document.querySelector( '.masonry-grid' );
	if ( !grid ) {
		return;
	}
	imagesLoaded( grid, () => {
		new Masonry( grid, {
			itemSelector: '.masonry-grid__item',
			columnWidth: '.masonry-grid__sizer',
			transitionDuration: 0
		} );
	} );
} )();

( () => {
	// Slideshow
	const element = document.querySelector( '.slideshow' );
	if ( !element ) {
		return;
	}
	const slideshow_state = element.hasAttribute( 'data-show-onload' );
	const slideshow = new Ten4Slideshow( element, slideshow_state, element.hasAttribute( 'data-create-prop' ) );
	const slide_interval = element.getAttribute( 'data-slide-interval' );
	const slideshow_interval = parseInt( slide_interval, 10 );
	const slideshow_event = element.getAttribute( 'data-event-listener-type' );
	const slideshow_pips_container = document.getElementById( 'js-pips-container' );
	if ( !slideshow_pips_container ) {
		return;
	}
	const slideshow_pips = slideshow_pips_container.querySelectorAll( '.slideshow__pip' );
	const slides = Array.from( document.querySelectorAll( '.slideshow__slide' ) );
	let timer_id = null;
	if ( slideshow_pips.length > 0 ) {
		slideshow_pips[0].classList.add( 'slideshow__pip--selected' );
		slideshow.addCallback( ( index ) => {
			for ( let i = 0; i < slideshow_pips.length; i += 1 ) {
				if ( i === index ) {
					slideshow_pips[i].classList.add( 'slideshow__pip--selected' );
					continue;
				}
				slideshow_pips[i].classList.remove( 'slideshow__pip--selected' );
			}
		} );
	}
	function resetInterval() {
		window.clearInterval( timer_id );
		timer_id = window.setInterval( () => {
			slideshow.slide();
		}, slideshow_interval );
	}
	function handlePipEvent( index ) {
		return () => {
			slideshow.slideTo( index );
			if ( slideshow_event === 'mouseover' ) {
				document.body.classList.add( 'nav-hidden' );
			}
		};
	}
	for ( let i = 0; i < slideshow_pips.length; i += 1 ) {
		slideshow_pips[i].addEventListener( slideshow_event, handlePipEvent( i ) );
		if ( slideshow_event === 'mouseover' ) {
			slideshow_pips[i].addEventListener( 'mouseout', () => {
				document.body.classList.remove( 'nav-hidden' );
				slides.filter( slide => {
					if ( slide.classList.contains( 'slideshow__slide--top' ) || slide.classList.contains( 'slideshow__slide--behind' ) ) {
						slide.classList.remove( 'slideshow__slide--top' );
						slide.classList.remove( 'slideshow__slide--behind' );
					}
				} );
			} );
		}
	}
	if ( slide_interval !== null ) {
		resetInterval();
	}
} )();

( () => {
	function handleToggle( toggler ) {
		const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );
		return function() {
			for ( let i = 0; i < togglees.length; i += 1 ) {
				if ( togglees[i].classList.contains( 'toggled' ) ) {
					togglees[i].classList.remove( 'toggled' );
					continue;
				}
				togglees[i].classList.add( 'toggled' );
			}
			if ( toggler.classList.contains( 'toggler--a' ) ) {
				toggler.classList.remove( 'toggler--a' );
				toggler.classList.add( 'toggler--b' );
			} else {
				toggler.classList.remove( 'toggler--b' );
				toggler.classList.add( 'toggler--a' );
			}
		};
	}
	const togglers = document.querySelectorAll( '.toggler' );
	for ( let i = 0; i < togglers.length; i += 1 ) {
		togglers[i].addEventListener( 'click', handleToggle( togglers[i] ) );
	}
} )();

( () => {
	// Search
	const search = document.querySelector( '.search' );
	const search_toggle = document.querySelector( '.header__search.toggler' );
	const search_input = document.getElementById( 'js-search-input' );

	if ( !search_toggle ) {
		return;
	}

	search_toggle.addEventListener( 'click', () => {
		window.setTimeout( () => {
			search_input.focus();
		}, 500 );
	} );

	window.addEventListener( 'keyup', ( e ) => {
		if ( e.keyCode === 27 ) {
			search.classList.remove( 'toggled' );
		}
	} );
} )();

( () => {
	// Scrollmatch column alignment
	function initColumnScroller() {
		const container = document.querySelector( '.scrollmatch' );
		if ( !container ) {
			return;
		}
		if ( container.offsetHeight < ( window.innerHeight || document.documentElement.clientHeight ) ) {
			return;
		}
		if ( getWindowWidth() <= breakpoints.g.width ) {
			return;
		}

		const container_items = container.querySelectorAll( '.col' );
		// check we have enough elements in each col
		for ( let i = 0; i < container_items.length; i++ ) {
			const new_count = container_items[i].childNodes.length;
			if ( new_count < 2 ) {
				return;
			}
		}

		function getTallestNode( nodes ) {
			let tallest_node = nodes[0];
			for ( let i = 0; i < nodes.length; i++ ) {
				if ( nodes[i].clientHeight > tallest_node.clientHeight ) {
					tallest_node = nodes[i];
				}
			}
			return tallest_node;
		}
		function makeLoopArray( tallest_col, nodes ) {
			const a = [];
			for ( let i = 0; i < nodes.length; i++ ) {
				if ( container_items[i] !== tallest_col ) {
					a.push( container_items[i] );
				}
			}
			return a;
		}

		function handleScroll() {
			const tallest_col = getTallestNode( container_items );
			const loop_items = makeLoopArray( tallest_col, container_items );
			return () => {
				const scrollY = document.body.clientTop - tallest_col.getBoundingClientRect().top;
				const padding = window.innerHeight;
				const tallest_height = tallest_col.clientHeight;
				if ( tallest_height > window.innerHeight ) {
					for ( let i = 0; i < loop_items.length; i++ ) {
						const el = loop_items[i];
						const dx = tallest_height - el.clientHeight;
						if ( scrollY < 0 ) {
							el.style.transform = 'translateY( 0 )';
							return;
						} else if ( scrollY >= ( tallest_height - padding ) ) {
							el.style.transform = 'translateY( ' + dx + 'px )';
							return;
						}
						const transT = ( scrollY * dx / ( tallest_height - padding ) );
						el.style.transform = 'translateY( ' + transT + 'px )';
					}
				}
			};
		}

		const doScrollHandling = handleScroll();
		window.addEventListener( 'scroll', doScrollHandling );
		window.addEventListener( 'resize', doScrollHandling );
		doScrollHandling(); // Do once on page load.
	}
	if ( typeof window.requestAnimationFrame !== 'undefined' ) {
		window.addEventListener( 'load', initColumnScroller );
	}

} )();

( () => {
	// Stop video playback when video is not in viewport

	const players = document.querySelectorAll( 'iframe' );
	if ( !players ) {
		return;
	}
	const options = {
		root: null,
		rootMargin: '0px',
		threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
	};

	let previous_y = 0;
	let previous_ratio = 0;

	const io = new IntersectionObserver( ( entries, observer ) => {
		for (let j = 0; j < entries.length; j++) {
			const entry = entries[j];
			const current_y = entry.boundingClientRect.y;
			const current_ratio = entry.intersectionRatio;
			const is_intersecting = entry.isIntersecting;
			const iframe_src = entry.target.src;
			if ( current_y < previous_y ) {
				if ( current_ratio < 0.2 && current_ratio < previous_ratio && is_intersecting ) {
					entry.target.src = iframe_src;
				}
			} else if ( current_y > previous_ratio && is_intersecting ) {
				if ( current_ratio < 0.2 && current_ratio < previous_ratio ) {
					entry.target.src = iframe_src;
				}
			}

			previous_y =  current_y;
			previous_ratio = current_ratio;
		}

	}, options );

	for (let i = 0; i < players.length; i++) {
		const target = players[i];
		io.observe( target );
	}


} )();

new LightBox();
