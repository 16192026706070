( function(){

	var search_form = document.getElementById( 'js-search' );
	var input = document.getElementById( 'js-search-input' );
	var buffer_container = document.createElement( 'div' );
	var titles = document.querySelectorAll( '.search__results__title' );

	var delay = ( function(){
		var timer = 0;
		return function( callback, ms ){
			clearTimeout( timer );
			timer = setTimeout( callback, ms );
		};
	} )();

	input.addEventListener( 'keyup', function(){
		var artists_search_result_container = document.getElementById( 'search-results-suggested-artists' );
		var exhibitions_search_result_container = document.getElementById( 'search-results-suggested-exhibitions' );
		delay( function(){
			if ( input.value.length > 0 ){
				makeRequest( input.value, artists_search_result_container, 'suggested-artists', 'suggested-template-artists', ['id', 'title', 'url'] );
				makeRequest( input.value, exhibitions_search_result_container, 'suggested-exhibitions', 'suggested-template-exhibitions', ['id', 'title', 'url', 'artist', 'start_date', 'end_date'] );
			}
			else {
				artists_search_result_container.innerHTML = "";
				exhibitions_search_result_container.innerHTML = "";
				for ( var k = 0; k < titles.length; k += 1 ) {
					titles[k].classList.add( 'removed' );
				}
			}
		}, 500 );
	} );

	function renderTemplate( id, variables ){
		var template = document.getElementById( id ).textContent;
		if( !template ){
			return '';
		}
		return template.replace( /%([^% ]+)%/gi, function( match, tag_name ){
			return variables[tag_name] || '';
		} );
	}

	function makeRequest( value, search_result_container, endpoint_slug, template_id, field_handles ){
		var base_url = document.getElementById( 'js-base-url' ).getAttribute( 'data-url' );
		var url = base_url + 'search/' + endpoint_slug + '/' + encodeURIComponent( value ) + '.json';
		ajax( {
			type: 'get',
			url: url,
			timeout: 10000,
			onSuccess: function( data ){
				var data = JSON.parse( data );
				var data = data.data;
				var html = '';
				search_result_container.innerHTML = '';
				for ( var k = 0; k < titles.length; k += 1 ) {
					titles[k].classList.remove( 'removed' );
				}
				if ( data.length < 1 ) {
					search_result_container.innerHTML = '<p class="theta">No results</p>';
					return;
				}
				for( var i = 0; i < data.length; i += 1 ){
					if ( data[i].hasOwnProperty( 'title' ) ) {
						var template_data = {};
						for( var j = 0; j < field_handles.length; j+= 1 ) {
							template_data[field_handles[j]] = data[i][field_handles[j]] || null;
						}
						html += renderTemplate( template_id, template_data );
						buffer_container.innerHTML = html;
					}
				}
				while ( buffer_container.hasChildNodes() ){
					search_result_container.appendChild( buffer_container.firstChild );
				}
			},
			onError: function(){
				search_result_container.innerHTML = '<p class="theta">No results</p>';
			}
		} );
	}

} )();
